// load all images from theme_url/img
function requireAll(r) { r.keys().forEach(r); }
requireAll(require.context('./img', true));

// import bootstrap js
//import './js/bootstrap.js';

import datepicker from 'js-datepicker'

// custom scss
import './scss/app.scss';

$(function () {

    $(".hamburger").click(function(){
        $(this).toggleClass("active");
        $("#portal-globalnav").toggleClass("active");
        $("#roleswitcher").toggleClass("active");
        $("#columnContentWrapper").toggleClass("active");
    });

    const topMenu = $(".left-col").first(),
        topMenuHeight = topMenu.outerHeight()+15,
        // All list items
        menuItems = topMenu.find("a"),
        // Anchors corresponding to menu items
        scrollItems = menuItems.map(function(){
            let item = $($(this).attr("href"));
            if (item.length) { return item; }
        });

    $(window).scroll(function(){
        // Get container scroll position
        let fromTop = $(this).scrollTop()+topMenuHeight;

        // Get id of current scroll item
        let cur = scrollItems.map(function(){
            if ($(this).offset().top < fromTop)
                return this;
        });
        // Get the id of the current element
        cur = cur[cur.length-1];
        let id = cur && cur.length ? cur[0].id : "";
        // Set/remove active class
        menuItems
            .parent().removeClass("active")
            .end().filter("[href='#"+id+"']").parent().addClass("active");
    });

    // datepicker
    // const minDate = new Date();
    // minDate.setDate(minDate.getDate() + 2);
    //
    // const picker = datepicker('[data-datepicker]', {
    //     formatter: (input, date, instance) => {
    //         input.value = date.toLocaleDateString()
    //     },
    //     startDay: 1,
    //     customDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    //     customMonths: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
    //     minDate: minDate,
    //     noWeekends: true
    // });

    // new tab footer links
    $('#belowcolumns a').on('click', (e) => {
        e.preventDefault();
        window.open(e.currentTarget.href, '_blank').focus();
    })

});